<template>
  <div class="admin-view-container compensation-flow-view">
    <div class="admin-table-search">
      <el-form
        ref="queryForm"
        :model="tableQuery"
        label-position="right"
        size="medium"
        @submit.native.prevent="search"
        v-loading="tableLoading"
        class="admin-tablequery">
        <el-form-item label="角色名称">
          <el-input placeholder="请输入角色名称" v-model="tableQuery.roleName" class="input-with-select"> </el-input>
        </el-form-item>
        <el-form-item style="margin-left: auto; width: auto; text-align: right">
          <el-button size="medium" type="primary" :loading="tableLoading" native-type="submit" class="admin-btn">
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <admin-space size="medium" class="admin-methods-btns" align="center">
      <el-button type="primary" @click="postHandler()">新增</el-button>
    </admin-space>
    <div class="admin-table-list" v-loading="tableLoading">
      <el-table :data="tableData.data" border height="100%">
        <el-table-column prop="roleName" label="角色名称"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" :formatter="$formatter.date(true)"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <admin-space class="admin-table-links">
              <el-link type="primary" :disabled="scope.row.roleId == 1" @click="postHandler(scope.row)">编辑</el-link>
              <el-link type="primary" :disabled="scope.row.roleId == 1" @click="deleteHandler(scope.row)">删除</el-link>
            </admin-space>
          </template>
        </el-table-column>
      </el-table>
      <div class="admin-pager-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tableQuery.page"
          :page-size="tableQuery.size"
          :total="tableData.total"
          :layout="tableConfig.layout"
          background></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {sysRoleGetList, sysRoleDelete} from '@/api/index';
import tableMinx from '@/mixin/table-page';
export default {
  mixins: [tableMinx(sysRoleGetList)],
  data() {
    return {
      loading: false,
      tableQuery: {
        roleName: '', // 角色名称
      },
    };
  },
  methods: {
    async postHandler(row) {
      let vNode = this.$createElement((await import('./post.vue')).default, {
        key: Math.random(),
        props: {
          row: row,
        },
        on: {
          success: () => {
            this.getTable();
          },
        },
      });

      this.$adminDialog(vNode, {
        title: row ? '编辑' : '新增',
        width: '800px',
      });
    },

    deleteHandler(row) {
      this.$adminConfirm('用以永久删除该数据').then(() => {
        this.loading = true;
        this.$utils.resolveRequest(sysRoleDelete(row), this, () => {
          this.getTable();
        });
      });
    },

    mixinParseData(data) {
      //mixin调用该方法 传入res.data.data数据 处理后返回data
      return data;
    },
  },
};
</script>

<style lang="scss">
.compensation-flow-view {
  .input-with-select {
    width: 280px;
  }
}
</style>
